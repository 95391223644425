import React, { useContext, useState, useEffect } from 'react';
import swal from 'sweetalert';
import agentebtcapi from 'agentebtcapi.js';
import { Spinner } from '@chakra-ui/react';
import DecimalsNum from 'helpers/decimals';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { InfoIcon } from '@chakra-ui/icons';
import ModalImage1 from 'images/FinishWithdraw.png';
import ModalImage2 from 'images/finishImage.png';
import Select from 'react-select';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Heading,
  Box,
  Stack,
  Text,
  useColorModeValue,
  Spacer,
  InputRightElement,
  InputGroup,
  Flex,
  Image,
  HStack,
  Input,
  useMediaQuery,
} from '@chakra-ui/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { AuthContext } from 'Auth';
import ReactGA from 'react-ga';
import analyticsCode from 'analyticsCode.js';

const CryptoMapedArray = {
  BTC: { value: 'Bitcoin' },
  USDT: { value: 'Tether' },
  RBTC: { value: 'Smart Bitcoin' },
  USDC: { value: 'USD Coin' },
  CKBTC: { value: 'Chain-key Bitcoin' },
  SOL: { value: 'Solana' },
  ADA: { value: 'Cardano' },
  SHIB: { value: 'Shiba Inu' },
  MATIC: { value: 'Matic' },
  ETH: { value: 'Ethereum' },
  DOGE: { value: 'Dogecoin' },
  //ALIC: { value: 'Alinticoin' },
};

/* Banks for PEN */
const BancoPENOptionsObject = {
  bcp: { value: 'bcp', label: 'Banco de Crédito del Perú (BCP)' },
  interbank: { value: 'interbank', label: 'Interbank' },
  scotiabank: { value: 'scotiabank', label: 'Scotiabank' },
  bbva: { value: 'bbva', label: 'Banco Continental (BBVA)' },
  pichincha: { value: 'pichincha', label: 'Banco Pichincha' },
  banbif: { value: 'banbif', label: 'Banco Banbif' },
  mibanco: { value: 'mibanco', label: 'Mibanco' },
  other: { value: 'other', label: 'Otro (Internacional)' },
};

/* Banks for HNL */
const BancoHNLOptionsObject = {
  bancoatlantida: { value: 'bancoatlantida', label: 'Banco Atlántida' }, // Change to real bank name
  bachonduras: { value: 'bachonduras', label: 'BAC Honduras' }, // Change to real bank name
  bancodavivienda: { value: 'bancodavivienda', label: 'Banco Davivienda' }, // Change to real bank name
  other: { value: 'other', label: 'Otro (Internacional)' },
};

/* Banks for USD */
const BancoUSDOptionsObject = {
  bcp: { value: 'bcp', label: 'Banco de Crédito del Perú (BCP)' },
  interbank: { value: 'interbank', label: 'Interbank' },
  scotiabank: { value: 'scotiabank', label: 'Scotiabank' },
  bbva: { value: 'bbva', label: 'Banco Continental (BBVA)' },
  pichincha: { value: 'pichincha', label: 'Banco Pichincha' },
  banbif: { value: 'banbif', label: 'Banco Banbif' },
  mibanco: { value: 'mibanco', label: 'Mibanco' },
  other: { value: 'other', label: 'Otro (Internacional)' },
};

const Checkbox = ({ label, value, onChange }) => {
  return (
    <label>
      <input
        type="checkbox"
        checked={value}
        onChange={onChange}
        style={{ marginRight: 7, paddingTop: 2, height: 10 }}
      />
      <a style={{ color: 'gray', fontSize: 10 }}>{label}</a>
    </label>
  );
};

const mapedCryptos = {
  BTC: { id: 0 },
  //ALIC: { id: 1 },
  USDT: { id: 1 },
  RBTC: { id: 2 },
  USDC: { id: 3 },
  CKBTC: { id: 4 },
  SOL: { id: 5 },
  ADA: { id: 6 },
  SHIB: { id: 7 },
  MATIC: { id: 8 },
  ETH: { id: 9 },
  DOGE: { id: 10 },
};

// Enable or disable minimum amount per operation
const TEST_CHECK_MINIMUM = true;

const Deposit = ({ history }) => {
  const { currentToken } = useContext(AuthContext);

  /*
   */

  /*
    {
      value: 'Tron USDT',
      image: 'https://changenow.io/images/cached/usdttrc20.png',
    },
  */

  let theme = createTheme({
    palette: {
      primary: {
        main: '#1A4BEA',
        dark: '#1A4BEA',
        light: '01fff0',
      },
    },
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedOption, setSelectedOption] = useState('PEN');
  const [valueToWithdraw, setValueToWithdraw] = useState('');
  const [cryptoValueToWithdraw, setCryptoValueToWithdraw] = useState('');
  const [cryptoAddressToWithdraw, setCryptoAddressToWithdraw] = useState('');
  const [cryptos, setCryptos] = useState([]);

  const [isClicked, setIsClicked] = useState(false);
  const [checked, setChecked] = React.useState(false);

  const [showEmail, setShowEmail] = React.useState(false);
  const [clientEmail, setClientEmail] = React.useState('');
  const [clientObject, setClientObject] = React.useState({});

  const [checkedCrypto, setCheckedCrypto] = React.useState(false);

  const [isSmallerThan640] = useMediaQuery('(max-width: 640px)');

  const [bankAccountNumberPEN, setBankAccountNumberPEN] = useState('---');
  const [bankAccountIdPEN, setBankAccountIdPEN] = useState('');
  const [bankNamePEN, setBankNamePEN] = useState('');
  const [bankBalance, setBankBalance] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [bankAccountNumberHNL, setBankAccountNumberHNL] = useState('');
  const [bankAccountIdHNL, setBankAccountIdHNL] = useState('');
  const [bankNameHNL, setBankNameHNL] = useState('');

  const [bankAccountNumberUSD, setBankAccountNumberUSD] = useState('---');
  const [bankAccountIdUSD, setBankAccountIdUSD] = useState('');
  const [bankNameUSD, setBankNameUSD] = useState('');

  const [feePerOperation, setFeePerOperation] = useState('-');
  const [totalFee, setTotalFee] = useState(0);
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [feeToken, setFeeToken] = useState('');

  const [manualFeePerOperation, setManualFeePerOperation] = useState(0.0005);
  const [minimumPerOperation, setMinimumPerOperation] = useState(0.0006);
  const [toAddressIsClient, setToAddressIsClient] = useState(false);

  const [userId, setUserId] = useState('');
  const [validatedLevel1, setValidatedLevel1] = useState(true);
  const [optionLabel, setOptionLabel] = useState('');
  const [loading, setLoading] = useState(false);

  const [fiatMinimum, setFiatMinimum] = useState(0);

  // For money format based on currency
  let dollarUSLocale = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: selectedOption === 'USDT' ? 'USD' : 'USD',
  });
  const [valueToDeposit, setValueToDeposit] = useState('');
  const [valueToDepositLabel, setValueToDepositLabel] = useState('');
  const [ruc, setRuc] = useState('');

  // Verify money format
  const IsNumeric = num => /^-{0,1}\d*\.{0,1}\d+$/.test(num);

  const isNumber = number => Number.isFinite(number);

  useEffect(() => {
    if (selectedOption === 'PEN') {
      setFiatMinimum(20);
    }
    if (selectedOption === 'USD') {
      setFiatMinimum(20);
    }
    if (selectedOption === 'HNL') {
      setFiatMinimum(100);
    }
  }, [selectedOption]);

  // Updates total label with currency and limits the decimal to 2 digits
  // Limit amount to 15,000
  // * Can refactorize

  /* Criptos and fiat displayed */
  const options = [
    {
      value: 'PEN',
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfd8ZgbLagr2z17duiTdfy4NT1xp45vG7u8w&usqp=CAU',
    },
    {
      value: 'HNL',
      image: 'https://i.imgur.com/cK9E867.png',
    },
    {
      value: 'USD',
      image:
        'https://w7.pngwing.com/pngs/666/76/png-transparent-flag-of-the-united-states-national-flag-usa-flag-flag-logo-united-states.png',
    },
    ...cryptos.map(crypto => {
      return {
        value: crypto.symbol,
        image: crypto.image,
      };
    }),
    // {
    //   value: 'USDT',
    //   image: 'https://logowik.com/content/uploads/images/tether-usdt1484.jpg',
    // },
    // {
    //   value: 'Tron USDT',
    //   image: 'https://changenow.io/images/cached/usdttrc20.png',
    // },
  ];

  const verifyMoneyFormat = value => {
    if (IsNumeric(value) || value === '') {
      if (value > 15000) {
        swal({
          text: `Los depósitos no pueden ser mayores a ${selectedOption}15,000`,
          button: false,
          timer: 2000,
        });
        return 0;
      }
      if (value.split('.').length - 1 < 2 && value.split('.')[1] < 100) {
        setValueToDeposit(value);
        setValueToDepositLabel(dollarUSLocale.format(value));
      } else if (value.split('.').length - 1 < 1) {
        setValueToDeposit(value);
        setValueToDepositLabel(dollarUSLocale.format(value));
      }
    } else {
      if (value.slice(-1) === '.' && value.split('.').length - 1 < 2) {
        setValueToDeposit(value);
        setValueToDepositLabel(dollarUSLocale.format(value));
      } else {
        swal({
          text: 'Formato inválido',
          button: false,
          timer: 1500,
        });
      }
    }
  };

  const resetValues = () => {
    setValueToWithdraw('');
    setCryptoValueToWithdraw('');
    //setCryptoAddressToWithdraw('');
    setChecked(false);
    setCheckedCrypto(false);
    setFeePerOperation('-');
    setTotalFee(0);
    setFeeToken('');
    setDebouncedInputValue('');
    setManualFeePerOperation(0);
    //setMinimumPerOperation(0);
    setToAddressIsClient(false);
    setSelectedNetwork('');
    setClientEmail('');
    setClientObject({});
  };

  // Get user information from API using token
  //    and set RUC variable
  const getUser = () => {
    let data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/user';
    let url = `${agentebtcapi}${endpoint}`;
    currentToken &&
      fetch(url, data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw { type: 'non-user' };
          }
        })
        .then(responseJson => {
          getWallet();
          setRuc(responseJson.ruc);
          setUserId(responseJson._id);
          setValidatedLevel1(responseJson.firstLevelApproved);
        })
        .catch(error => {
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });
        });
  };

  // Get Wallets
  const getWallet = () => {
    let data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/wallet';
    let url = `${agentebtcapi}${endpoint}`;
    currentToken &&
      fetch(url, data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw { type: 'non-wallet' };
          }
        })
        .then(responseJson => {
          responseJson.map(singleJson => {
            setBankBalance(bankBalance => ({
              ...bankBalance,
              [singleJson.currency]: singleJson.balance,
            }));
          });
        })
        .catch(error => {
          swal({
            text: 'Ha habido un error en nuestro sistema',
            button: false,
            timer: 1000,
          });
        });
  };

  const getBankAccounts = () => {
    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/user/bank`;
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(responseJson => {
        let bankAccountsData = responseJson.banks;

        console.log(responseJson);
        if (responseJson.pen != 0) {
          bankAccountsData.forEach(function(item) {
            if (item.currency == 'PEN') {
              setBankAccountNumberPEN(item.accountNumber);
              setBankAccountIdPEN(item._id);
              setBankNamePEN(item.name);
            }
          });
        }

        {
          /* AFC: Add new section for new fiat currency */
        }
        if (responseJson.hnl != 0) {
          bankAccountsData.forEach(function(item) {
            if (item.currency == 'HNL') {
              setBankAccountNumberHNL(item.accountNumber);
              setBankAccountIdHNL(item._id);
              setBankNameHNL(item.name);
            }
          });
        }
        if (responseJson.usd != 0) {
          bankAccountsData.forEach(function(item) {
            if (item.currency == 'USD') {
              setBankAccountNumberUSD(item.accountNumber);
              setBankAccountIdUSD(item._id);
              setBankNameUSD(item.name);
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const createWithdrawal = async () => {
    setIsClicked(true);
    let totalAmount =
      selectedOption == 'PEN' ? valueToWithdraw - 3.0 : valueToWithdraw - 1.0;
    let finalBankAccountId =
      selectedOption == 'PEN' ? bankAccountIdPEN : bankAccountIdUSD;

    switch (selectedOption) {
      case 'PEN':
        totalAmount = valueToWithdraw - 3.0;
        finalBankAccountId = bankAccountIdPEN;
        break;
      case 'USD':
        totalAmount = valueToWithdraw - 1.0;
        finalBankAccountId = bankAccountIdUSD;
        break;
      case 'HNL':
        totalAmount = valueToWithdraw - 52.0;
        finalBankAccountId = bankAccountIdHNL;
        break;
    }

    const jsonBody = JSON.stringify({
      amount: totalAmount,
      currency: selectedOption,
      bankId: finalBankAccountId,
    });

    let data = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: jsonBody,
    };
    let endpoint = '/client/wallet/withdraw';
    let url = `${agentebtcapi}${endpoint}`;

    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          setIsClicked(true);
          return response.json();
        } else {
          return response.json();
          //swal({text: "Ha habido un error al crear la solicitud de retiro.", button: false, timer: 5000});
        }
      })
      .then(responseJson => {
        if (responseJson.type != 'withdraw-requested') {
          swal({
            text: responseJson.msg,
            button: false,
            timer: 5000,
          });
        } else {
          confirmWithdrawal(responseJson.transaction._id);
          swal({
            text: 'Retiro solicitado correctamente',
            button: false,
            timer: 5000,
          });
        }

        console.log(responseJson);
      })
      .catch(error => {
        swal({
          text:
            'Ha habido un error al crear la solicitud de retiro. Intenta más tarde.',
          button: false,
          timer: 5000,
        });
      });
  };

  const confirmWithdrawal = async withdrawalId => {
    let data = {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    let endpoint = '/client/wallet/withdraw/confirm/' + withdrawalId;
    let url = `${agentebtcapi}${endpoint}`;
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          swal({
            text: 'Ha habido un error al confirmar la solicitud de retiro.',
            button: false,
            timer: 5000,
          });
        }
      })
      .then(responseJson => {
        console.log('Retiro confirmado');
      })
      .catch(error => {
        swal({
          text: 'Ha habido un error al confirmar el retiro. Intenta más tarde.',
          button: false,
          timer: 5000,
        });
      });
  };

  const checkAddress = async address => {
    console.log(`Verificando dirección ${selectedOption}`);

    let data = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = '/client/wallet/isInternalClient/' + address;
    let url = `${agentebtcapi}${endpoint}`;
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        console.log(responseJson);
        if (responseJson.client == true) {
          setToAddressIsClient(true);
          // switch (selectedOption) {
          //   case 'BTC':
          //     setFeePerOperation(0.00015);
          //     setMinimumPerOperation(0.0006);
          //     break;
          //   case 'USDT':
          //     setFeePerOperation(0.3);
          //     setMinimumPerOperation(2);
          //     break;
          //   case 'Tron USDT':
          //     setFeePerOperation(0.3);
          //     setMinimumPerOperation(2);
          //     break;
          // }
        } else {
          setToAddressIsClient(false);
          // switch (selectedOption) {
          //   case 'BTC':
          //     setFeePerOperation(0.0005);
          //     setMinimumPerOperation(0.0006);
          //     break;
          //   case 'USDT':
          //     setFeePerOperation(0.5);
          //     setMinimumPerOperation(2);
          //     break;
          //   case 'Tron USDT':
          //     setFeePerOperation(0.5);
          //     setMinimumPerOperation(2);
          //     break;
          // }
        }
      })
      .catch(error => {
        console.log('Error al verificar la direccion ...');
        setFeePerOperation('-');
        setManualFeePerOperation(0);
        setMinimumPerOperation(0.0006);
      });
  };

  const getCryptos = async () => {
    const token = localStorage.getItem('token-agente-btc');
    const endpoint = '/public/cryptos';
    const url = `${agentebtcapi}${endpoint}`;
    const data = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        setCryptos(responseJson.filter(fc => fc.allowWithdraw));
      })
      .catch(error => {
        console.log('Ha habido un error en nuestro sistema');
      });
  };

  useEffect(() => {
    setFeePerOperation('-');
    setManualFeePerOperation(0);
    setTotalFee(0);
    setFeeToken('');
    getUser();
    getBankAccounts();
    getWallet();
    getCryptos();
  }, [currentToken]);

  useEffect(() => {
    setCryptoAddressToWithdraw('');
  }, [showEmail]);

  useEffect(() => {
    setFeePerOperation('-');
    setManualFeePerOperation(0);
    setTotalFee(0);
    setCryptoAddressToWithdraw('');
    setFeeToken('');
    setManualTransactionFee(selectedOption);
    setShowEmail(false);

    // Get minimum amount per operation
    let currentCrypto = cryptos.filter(it => it.symbol === selectedOption);
    if (currentCrypto.length > 0) {
      setMinimumPerOperation(currentCrypto[0].limitL1Transfer[0]);
    }
  }, [selectedOption]);

  const setManualTransactionFee = _currency => {
    return;
    let currentCrypto = cryptos.filter(
      it => it.symbol === _currency && it.isManual
    );
    if (currentCrypto.length > 0) {
      setManualFeePerOperation(currentCrypto[0].transactionExternalFee);
      console.log('Change fee: ', currentCrypto[0].transactionExternalFee);
      return;
    }
    setManualFeePerOperation(0);
    console.log('No Change');
    return;
  };

  const getEstimatedFee = async amount => {
    let finalNetwork = `${
      typeof selectedNetwork === 'undefined' ? '' : selectedNetwork
    }`;
    if (finalNetwork === '' || !finalNetwork) {
      finalNetwork = 'MAINNET';
    }
    const token = localStorage.getItem('token-agente-btc');
    const endpoint = '/client/transaction/estimate-fee';
    const url = `${agentebtcapi}${endpoint}`;
    const jsonBody = JSON.stringify({
      amount: amount,
      currency: selectedOption,
      toAddress: cryptoAddressToWithdraw,
      network: finalNetwork,
      isInternal: toAddressIsClient,
      forceInternal: showEmail,
    });
    console.log('Fee: ', jsonBody);
    const data = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: jsonBody,
    };
    await fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 408) {
            setLoading(false);
            swal({
              text:
                'El correo electrónico ingresado no se encuentra registrado en nuestro sistema. Por favor verifique e intente nuevamente.',
              button: false,
              timer: 7000,
            });
            setActiveStep(1);
            return false;
          }
          if (response.status === 501) {
            setLoading(false);
            swal({
              text:
                'No se puede enviar a esta dirección. Por favor intente usando el correo electrónico del destinatario en AgenteBTC.',
              button: false,
              timer: 7000,
            });
            setActiveStep(1);
            return false;
          }

          if (response.status === 502) {
            setLoading(false);
            swal({
              text:
                'El sistema no puede completar la acción en este momento, por favor vuelve a intentarlo en 10 minutos, si persiste comunícate con soporte@agentebtc.com',
              button: false,
              timer: 12000,
            });
            setActiveStep(1);
            return false;
          }
          throw { type: 'non-user' };
        }
      })
      .then(responseJson => {
        if (!responseJson) {
          return;
        }
        setLoading(false);
        // if( responseJson.totalFee === 0 ){
        //   setFeePerOperation(0);
        //   setManualFeePerOperation(0);
        //   setFeeToken("");
        //   swal({
        //     text:
        //       'La dirección de destino o el monto de transacción son inválidos. Por favor verifique los datos ingresados.',
        //     button: false,
        //     timer: 5000,
        //   });
        //   return;
        // }
        setFeePerOperation(responseJson.totalFee ?? 0);
        setManualFeePerOperation(responseJson.totalFee ?? 0);
        setFeeToken(responseJson.feeToken);
      })
      .catch(error => {
        setLoading(false);
        swal({
          text:
            'La dirección de destino o el monto de transacción son inválidos. Por favor verifique los datos ingresados.',
          button: false,
          timer: 5000,
        });
      });
  };

  // Debounce input value for fee estimation
  useEffect(() => {
    setTotalFee(0);
    setLoading(true);
    setFeePerOperation('-');
    setManualFeePerOperation(0);
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(cryptoValueToWithdraw);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [cryptoValueToWithdraw, 2000]);

  // Debounce input value for fee estimation
  useEffect(() => {
    // Validate input value is a float number
    if (debouncedInputValue === '') {
      setLoading(false);
      return;
    }
    var valid =
      !/^\s*$/.test(debouncedInputValue) && !isNaN(debouncedInputValue);
    if (valid && parseFloat(debouncedInputValue) > 0) {
      getEstimatedFee(debouncedInputValue);
    } else {
      if (parseFloat(debouncedInputValue) === 0) {
        setLoading(false);
        setFeePerOperation(0);
        setManualFeePerOperation(0);
        return;
      }
      setLoading(false);
      swal({
        text:
          'La dirección de destino o el monto de transacción son inválidos. Por favor verifique los datos ingresados.',
        button: false,
        timer: 5000,
      });
    }
  }, [debouncedInputValue]);

  const sendCrypto = async () => {
    setIsClicked(true);

    // let body = {
    //   toAddress: cryptoAddressToWithdraw,
    //   amount: cryptoValueToWithdraw - feePerOperation,
    // };

    // //AGREGAR VALIDACION CON FEE

    // const encodeFormData = data => {
    //   return Object.keys(data)
    //     .map(
    //       key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
    //     )
    //     .join('&');
    // };

    // let data = {
    //   method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //   mode: 'cors', // no-cors, *cors, same-origin
    //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //   credentials: 'same-origin', // include, *same-origin, omit
    //   headers: {
    //     Authorization: `Bearer ${currentToken}`,
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //   },
    //   body: encodeFormData(body),
    // };

    let sendBody = {
      toAddress: cryptoAddressToWithdraw,
      amount: cryptoValueToWithdraw,
      currency: selectedOption,
      network: selectedNetwork,
      feeToken: feeToken,
      forceInternal: showEmail,
    };

    const jsonBody = JSON.stringify(sendBody);

    // const jsonBody =
    //   selectedOption === 'BTC'
    //     ? JSON.stringify({
    //         toAddress: cryptoAddressToWithdraw,
    //         amount: cryptoValueToWithdraw - feePerOperation,
    //       })
    //     : JSON.stringify({
    //         toAddress: cryptoAddressToWithdraw,
    //         currency: selectedOption,
    //         amount: cryptoValueToWithdraw ,
    //         network: selectedNetwork,
    //       });

    //AGREGAR VALIDACION CON FEE

    let data = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: jsonBody,
    };

    let endpointSend = 'sendBitcoin';
    // switch (selectedOption) {
    //   case 'BTC':
    //     endpointSend = 'sendBitcoin';
    //     break;
    //   default:
    //     endpointSend = 'withdraw/crypto-withdraw';
    // }

    let endpoint = `/client/wallet/${endpointSend}`;
    let url = `${agentebtcapi}${endpoint}`;

    await fetch(url, data)
      .then(async response => {
        if (response.ok) {
          if (selectedOption === 'CKBTC') {
            // Get a random number between 5500 and 8000
            let randomTime =
              Math.floor(Math.random() * (9000 - 6500 + 1)) + 6500;
            // Generate a artificial delay
            await new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve(response);
              }, randomTime);
            });
          }

          setIsClicked(false);
          swal({
            text: '¡Envío Exitoso!',
            button: true,
            timer: 7000,
          });
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          return response.json();
        } else {
          console.log(response);
          setIsClicked(false);
          swal({
            text:
              'Ha habido un error en la operación. Si el error persiste escribenos a Soporte.',
            button: false,
            timer: 5000,
          });
        }
      })
      .then(responseJson => {})
      .catch(error => {
        setIsClicked(false);
        swal({
          text:
            'Ha habido un error en nuestro sistema. Si el error persiste escribenos a Soporte.',
          button: false,
          timer: 5000,
        });
      });
  };

  const changeOptionLabel = () => {
    const preLabel =
      'Declaro que la dirección ingresada pertenece a una dirección de la red';
    switch (selectedOption) {
      case 'BTC':
        return `${preLabel} Bitcoin (BTC)`;
      case 'USDT':
        return `${preLabel} ERC20 USDT`;
      case 'Tron USDT':
        return `${preLabel} TRC20 USDT`;
    }
  };

  const isNumberOrFloat = num => {
    return !isNaN(parseFloat(num)) && isFinite(num);
  };

  // const changeValueToWithdrawManual = amount => {
  //   console.log(amount);
  //   if (isNumberOrFloat(amount) || amount === '') {
  //     switch (selectedOption) {
  //       case 'BTC':
  //         setCryptoValueToWithdraw(amount);
  //         break;
  //       case 'USDT':
  //         setCryptoValueToWithdraw(amount);
  //         break;
  //       case 'Tron USDT':
  //         setCryptoValueToWithdraw(amount);
  //         break;
  //     }
  //     return;
  //   }

  //   console.log('No pasa');
  // };

  //////// END MTC

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleChangeCrypto = () => {
    setCheckedCrypto(!checkedCrypto);
  };

  /* Next button */
  const handleNext = () => {
    if (activeStep === 0) {
      if (validatedLevel1) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else {
        swal({
          text: `Es necesario validar su identidad para continuar`,
          button: false,
          timer: 2000,
        });
      }
      return;
    }

    if (activeStep === 1) {
      // Crypto withdraw
      /* AFC: Add options for new fiat currency */
      if (
        selectedOption !== 'USD' &&
        selectedOption !== 'PEN' &&
        selectedOption !== 'HNL'
      ) {
        if (!cryptoAddressToWithdraw) {
          swal({
            text: `Ingrese una dirección válida para continuar.`,
            button: false,
            timer: 2000,
          });
          return;
        }

        if (!checkedCrypto) {
          swal({
            text: `Debe aceptar la declaración de información proporcionada.`,
            button: false,
            timer: 2000,
          });
          return;
        }

        if (selectedOption !== 'BTC' && selectedNetwork === '') {
          swal({
            text: `Seleccione una red para continuar.`,
            button: false,
            timer: 2000,
          });
          return;
        }

        //Continua a monto a transferir
        checkAddress(cryptoAddressToWithdraw);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else if (
        /* AFC: Add options for new fiat currency */
        (bankAccountNumberPEN !== '---' && selectedOption === 'PEN') ||
        (bankAccountNumberUSD !== '---' && selectedOption === 'USD') ||
        (bankAccountNumberHNL !== '---' && selectedOption === 'HNL')
      ) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else {
        swal({
          text: `Debe crear una cuenta de banco para continuar`,
          button: false,
          timer: 2000,
        });
      }

      return;
    }

    if (activeStep === 3) {
      createWithdrawal();
      getUser();
      getBankAccounts();
      getWallet();
    }

    if (activeStep === 2) {
      if (
        selectedOption !== 'USD' &&
        selectedOption !== 'PEN' &&
        selectedOption !== 'HNL'
      ) {
        if (!isNumberOrFloat(cryptoValueToWithdraw)) {
          swal({
            text: `Formato de monto inválido`,
            button: false,
            timer: 3000,
          });
          return;
        }

        if (TEST_CHECK_MINIMUM) {
          if (cryptoValueToWithdraw < minimumPerOperation) {
            swal({
              text: `Esta operación no cumple con el mínimo requerido`,
              button: false,
              timer: 3000,
            });
            return;
          }
        }

        if (cryptoValueToWithdraw > bankBalance[selectedOption]) {
          swal({
            text: `No cuentas con saldo suficiente.`,
            button: false,
            timer: 3000,
          });
          return;
        }

        // Aquí enviar BTCs

        //miXAxmJoGb8CCvkRphkxmwX65wHSztm6cG
        //0.00267098
        sendCrypto();
        return;
      } else {
        if (!IsNumeric(valueToWithdraw)) {
          swal({
            text: `Formato inválido`,
            button: false,
            timer: 2000,
          });
          return;
        }

        if (selectedOption === 'PEN') {
          if (valueToWithdraw < 20) {
            swal({
              text: `El monto mínimo es PEN 20`,
              button: false,
              timer: 2000,
            });
            return;
          }
          if (valueToWithdraw > bankBalance['PEN']) {
            swal({
              text: `No tiene suficientes fondos`,
              button: false,
              timer: 2000,
            });
            return;
          }
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        }

        /* ------------------------- */
        {
          /* AFC: Add values for HNL */
        }
        /* ------------------------- */
        if (selectedOption === 'HNL') {
          console.log('Entro a HNL');
          if (valueToWithdraw < 100) {
            swal({
              text: `El monto mínimo es HNL 100`, // Change to real number
              button: false,
              timer: 2000,
            });
            return;
          }
          if (valueToWithdraw > bankBalance['HNL']) {
            swal({
              text: `No tiene suficientes fondos`, // Change to real number
              button: false,
              timer: 2000,
            });
            return;
          }
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        }

        if (selectedOption === 'USD') {
          if (valueToWithdraw < 20) {
            swal({
              text: `El monto mínimo es $20`,
              button: false,
              timer: 2000,
            });
            return;
          }
          if (valueToWithdraw > bankBalance['USD']) {
            swal({
              text: `No tiene suficientes fondos`,
              button: false,
              timer: 2000,
            });
            return;
          }
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
      }
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    resetValues();
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    window.location.href = '/app/profile/withdraw';
  };

  console.log(selectedOption);

  /* Steps for withdrawing crypto */
  const stepsCrypto = [
    {
      label: '¿Qué moneda deseas retirar?',
      content: (
        <Box
          style={{ width: isSmallerThan640 ? '100%' : '60%' }}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          {options.map(value => {
            return (
              <Chip
                color={selectedOption === value.value ? 'primary' : 'default'}
                clickable
                onClick={() => {
                  setSelectedOption(value.value);
                  // setSelectedNetwork(
                  //   cryptos[mapedCryptos[value.value]?.id]?.addresses &&
                  //     cryptos[mapedCryptos[value.value]?.id]?.addresses[0]?.name
                  // );
                  setSelectedNetwork('');
                }}
                avatar={<Avatar src={value.image} />}
                label={value.value}
                style={{ marginInline: 5, marginBottom: 10 }}
              />
            );
          })}
        </Box>
      ),
    },
    {
      label: `${
        showEmail
          ? 'Ingresa el correo electrónico de la cuenta en AgenteBTC'
          : 'Ingresa la dirección a donde enviaremos tus criptomonedas'
      }`,
      content: (
        <Box style={{ width: isSmallerThan640 ? '100%' : '40%' }}>
          {selectedOption !== 'BTC' && selectedOption !== 'CKBTC' && (
            <Flex
              align="center"
              mt={15}
              mb={15}
              style={{
                width: '100%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                style={{
                  padding: 5,
                  fontSize: 12,
                  cursor: 'pointer',
                  backgroundColor: '#215aea',
                  color: 'white',
                  borderRadius: 5,
                }}
                ml={0}
                onClick={() => setShowEmail(!showEmail)}
              >
                <b>
                  {showEmail
                    ? 'Enviar a una dirección externa'
                    : 'Enviar a una cuenta en AgenteBTC usando correo electrónico'}
                </b>
              </Box>
            </Flex>
          )}

          <Input
            w={'100%'}
            style={{
              padding: 5,
              borderWidth: 1,
              borderColor: 'lightgrey',
              borderRadius: 5,
            }}
            placeholder={`${
              showEmail
                ? 'Correo electrónico de la cuenta en AgenteBTC'
                : 'Dirección de la billetera de destino'
            }`}
            value={cryptoAddressToWithdraw}
            onChange={event =>
              setCryptoAddressToWithdraw(event.target.value.trim())
            }
          />
          {selectedOption !== 'BTC' && (
            <>
              {' '}
              <Text
                color="#1A4BEA"
                fontSize={'15px'}
                fontWeight="bold"
                mt={10}
                mb={10}
              >
                Network
              </Text>
              <Select
                value={{
                  value: selectedNetwork,
                  label:
                    selectedNetwork === '' ? 'Seleccione' : selectedNetwork,
                }}
                isSearchable={false}
                onChange={network => setSelectedNetwork(network.value)}
                name="crypto"
                options={cryptos[
                  mapedCryptos[selectedOption]?.id
                ]?.addresses.map(address => {
                  console.log(address.name);
                  return { value: address.name, label: address.name };
                })}
                styles={{
                  control: (provided, state) => ({
                    ...provided,

                    height: 46,
                  }),
                  container: (provided, state) => ({
                    ...provided,

                    maxWidth: 150,
                    minWidth: 145,
                  }),
                }}
                className="basic-single"
                classNamePrefix="select"
              />
            </>
          )}
          <Text align="center" color="gray" fontSize={'12px'}>
            {cryptoAddressToWithdraw
              ? `${
                  showEmail
                    ? `Enviar a cuenta en AgenteBTC: ${cryptoAddressToWithdraw}`
                    : `Dirección de envío: ${cryptoAddressToWithdraw}`
                }`
              : ''}
          </Text>
          <Flex align="center" mt={15}>
            <InfoIcon color="#F39200" />

            {showEmail && (
              <Box
                style={{
                  padding: 5,
                  backgroundColor: '#F3920029',
                  fontSize: 9,
                }}
                ml={10}
              >
                <b>Importante!</b> Es tu responsabilidad asegurarte que el
                correo ingresado pertenece a una cuenta en AgenteBTC.
              </Box>
            )}

            {!showEmail && (
              <Box
                style={{
                  padding: 5,
                  backgroundColor: '#F3920029',
                  fontSize: 9,
                }}
                ml={10}
              >
                <b>Importante!</b> Es tu responsabilidad estar seguro de que la
                dirección ingresada sea una dirección de la red
                {` ${CryptoMapedArray[selectedOption]?.value} (${selectedOption})`}
              </Box>
            )}
          </Flex>

          {showEmail && (
            <Checkbox
              label={`Declaro que he verificado que el correo electrónico ingresado pertenece a una cuenta activa en AgenteBTC.`}
              value={checkedCrypto}
              onChange={handleChangeCrypto}
            />
          )}
          {!showEmail && (
            <Checkbox
              label={`Declaro que la dirección ingresada pertenece a una dirección de la red ${CryptoMapedArray[selectedOption]?.value} (${selectedOption})`}
              value={checkedCrypto}
              onChange={handleChangeCrypto}
            />
          )}
        </Box>
      ),
    },
    {
      label: '¿Cuánto quieres transferir?',
      content: (
        <Box style={{ width: isSmallerThan640 ? '100%' : '40%' }}>
          <InputGroup size="md" w={'100%'}>
            <Input
              w={'100%'}
              style={{
                padding: 5,
                borderWidth: 1,
                borderColor: 'lightgrey',
                borderRadius: 5,
              }}
              value={cryptoValueToWithdraw}
              onChange={event => {
                setCryptoValueToWithdraw(event.target.value);
              }}
              placeholder="0.00"
            />
            <InputRightElement h={'100%'} pr={5}>
              {manualFeePerOperation > 0 ? (
                <Button
                  variant="text"
                  size="small"
                  onClick={() =>
                    parseFloat(
                      setCryptoValueToWithdraw(
                        bankBalance[selectedOption] - manualFeePerOperation
                      )
                    )
                  }
                >
                  Total
                </Button>
              ) : (
                <Button
                  variant="text"
                  size="small"
                  onClick={() =>
                    parseFloat(
                      setCryptoValueToWithdraw(
                        (bankBalance[selectedOption] - feePerOperation).toFixed(
                          8
                        )
                      )
                    ).toFixed(8)
                  }
                >
                  Total
                </Button>
              )}
            </InputRightElement>
          </InputGroup>

          <Text align="right" color="gray" fontSize={'10px'}>
            {`Monto en billetera: ${parseFloat(
              bankBalance[selectedOption]
            ).toFixed(DecimalsNum(selectedOption))}`}
            <br />

            {manualFeePerOperation > 0
              ? parseFloat(
                  bankBalance[selectedOption] - manualFeePerOperation
                ).toFixed(DecimalsNum(selectedOption)) > 0
                ? `Monto Disponible: ${parseFloat(
                    bankBalance[selectedOption] - manualFeePerOperation
                  ).toFixed(DecimalsNum(selectedOption))}`
                : 'Monto disponible: 0'
              : feePerOperation !== '-'
              ? `Monto Disponible: ${parseFloat(
                  bankBalance[selectedOption] - feePerOperation
                ).toFixed(DecimalsNum(selectedOption))}`
              : loading
              ? 'Calculando'
              : 'Ingrese monto a transferir'}
          </Text>

          <Box
            style={{
              borderWidth: 1,
              borderColor: 'lightgrey',
              padding: 10,
              borderRadius: 10,
              color: 'lightgrey',
              marginTop: 10,
            }}
          >
            <Flex backgroundColor="#F6F6F6" mt="10">
              <Box p="4" bg="red.400" fontSize={'12px'} color="gray">
                {`Saldo de ${CryptoMapedArray[selectedOption]?.value} (${selectedOption})`}
              </Box>
              <Spacer />
              <Box p="4" bg="green.400" fontSize={'12px'} color="#1A4BEA">
                {parseFloat(bankBalance[selectedOption]).toFixed(
                  DecimalsNum(selectedOption)
                )}
              </Box>
            </Flex>

            {minimumPerOperation > 0 ? (
              <Flex>
                <Box p="4" bg="red.400" fontSize={'12px'} color="gray">
                  Retiro mínimo
                </Box>
                <Spacer />
                <Box p="4" bg="green.400" fontSize={'12px'} color="#1A4BEA">
                  {minimumPerOperation}
                </Box>
              </Flex>
            ) : (
              ''
            )}

            <Flex backgroundColor="#F6F6F6">
              <Box p="4" bg="red.400" fontSize={'12px'} color="gray">
                Comisión de red
              </Box>
              <Spacer />
              <Box p="4" bg="green.400" fontSize={'12px'} color="#1A4BEA">
                {manualFeePerOperation > 0
                  ? manualFeePerOperation.toFixed(8)
                  : feePerOperation !== '-'
                  ? feePerOperation
                  : loading
                  ? 'Calculando'
                  : 'Ingrese un monto'}

                {/* { `${totalFee > 0 ? ` (${totalFee} ${selectedOption})` : '-'}`} */}
              </Box>
            </Flex>

            {selectedOption !== 'BTC' && selectedNetwork !== '' && (
              <Flex backgroundColor="#F6F6F6">
                <Box p="4" bg="red.400" fontSize={'12px'} color="gray">
                  Red
                </Box>
                <Spacer />
                <Box p="4" bg="green.400" fontSize={'12px'} color="#1A4BEA">
                  {selectedNetwork}
                </Box>
              </Flex>
            )}
          </Box>

          {toAddressIsClient === false &&
          (manualFeePerOperation > 0 || feePerOperation !== '-') ? (
            <Flex align="center" mt={15}>
              <InfoIcon color="#F39200" />
              <Box
                style={{
                  padding: 5,
                  backgroundColor: '#F3920029',
                  fontSize: 9,
                }}
                ml={10}
              >
                La dirección ingresada{' '}
                {`${
                  showEmail
                    ? ' es de AgenteBTC, por lo cual no tiene comisión'
                    : ' no es de AgenteBTC, por lo cuál cuenta con una pequeña comisión de retiro'
                }`}
                .{' '}
                {manualFeePerOperation > 0
                  ? `Esta es ${manualFeePerOperation.toFixed(8)}`
                  : feePerOperation > 0
                  ? `Esta es ${feePerOperation}`
                  : ''}
              </Box>
            </Flex>
          ) : (
            ''
          )}

          {toAddressIsClient === true &&
          (manualFeePerOperation > 0 || feePerOperation !== '-') ? (
            <Flex align="center" mt={15}>
              <InfoIcon color="#F39200" />
              <Box
                style={{
                  padding: 5,
                  backgroundColor: '#F3920029',
                  fontSize: 9,
                }}
                ml={10}
              >
                La dirección ingresada es de Agente BTC, por lo cuál no tiene
                comisión de retiro.{' '}
                {manualFeePerOperation > 0
                  ? `Esta es ${manualFeePerOperation}`
                  : feePerOperation > 0
                  ? `Esta es ${feePerOperation}`
                  : ''}
              </Box>
            </Flex>
          ) : (
            ''
          )}
        </Box>
      ),
    },
  ];

  /* Steps for withdrawing fiat */
  const stepsFiat = [
    /* Choosing a fiat */
    {
      label: '¿Qué moneda deseas retirar?',
      content: (
        <>
          {validatedLevel1 === false ? (
            <Text
              backgroundColor="#486FEE12"
              fontSize={'14px'}
              mb={10}
              style={{ width: isSmallerThan640 ? '100%' : '100%' }}
              mt={15}
              borderRadius={8}
            >
              <a href="app/profile/validacion">
                Necesitas validar tu identidad para continuar.{' '}
              </a>
            </Text>
          ) : (
            ''
          )}

          <Box
            style={{ width: isSmallerThan640 ? '100%' : '60%' }}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            {options.map(value => {
              return (
                <Chip
                  color={selectedOption === value.value ? 'primary' : 'default'}
                  clickable
                  onClick={() => {
                    setSelectedOption(value.value);
                    // setSelectedNetwork(
                    //   cryptos[mapedCryptos[value.value]?.id]?.addresses &&
                    //     cryptos[mapedCryptos[value.value]?.id]?.addresses[0]
                    //       ?.name
                    // );
                    setSelectedNetwork('');
                  }}
                  avatar={<Avatar src={value.image} />}
                  label={value.value}
                  style={{ marginInline: 5, marginBottom: 10 }}
                />
              );
            })}
          </Box>
        </>
      ),
    },

    /* AFC: Add banks for new fiat currency */
    /* Choosing a bank account */
    {
      label: '¿A que banco deseas retirar?',
      content: (
        <Box style={{ width: isSmallerThan640 ? '100%' : '40%' }}>
          {/*
          <Select icon='' w={'100%'}
              onChange={ () => alert("cambio")}
              style={{
                padding: 5,
                borderWidth: 1,
                borderColor: 'lightgrey',
                borderRadius: 5,
                width: '100%',
                marginBottom: 10
              }}>
              <option value='0'>Selecciona una cuenta de banco</option>
              <option value='1'>Otro banco</option>
            </Select>*/}
          <Box
            mb={8}
            style={{
              backgroundColor: '#1A4BEA29',
              borderRadius: 8,
              padding: 5,
            }}
          >
            <Text align="left" color="gray" fontSize={'13px'}>
              Banco
            </Text>
            <Text align="left" color="gray" fontSize={'14px'} fontWeight="bold">
              {selectedOption === 'PEN'
                ? BancoPENOptionsObject[bankNamePEN]?.label
                : selectedOption === 'HNL'
                ? BancoHNLOptionsObject[bankNameHNL]?.label
                : BancoUSDOptionsObject[bankNameUSD]?.label}
              <br></br>
              {selectedOption === 'PEN'
                ? bankAccountNumberPEN
                : selectedOption === 'HNL'
                ? bankAccountNumberHNL
                : bankAccountNumberUSD}
            </Text>
          </Box>
          <Flex align="center" mt={15}>
            <>
              <InfoIcon color="#F39200" />
              <Box
                style={{
                  padding: 5,
                  backgroundColor: '#F3920029',
                  fontSize: 9,
                }}
                ml={10}
              >
                <b>Importante!</b> La cuenta bancaria elegida para realizaro tu
                retiro, debe estar a tu nombre. De lo contrario la operación
                sera rechazada.
              </Box>
            </>
          </Flex>
          <Flex align="center" mt={15}>
            <Box
              style={{
                padding: 5,
                backgroundColor: '#F3920029',
                fontSize: 9,
              }}
              w="90%"
              ml={25}
            >
              <b>Importante!</b> Si desea recibir su dinero a una cuenta
              diferente a BCP, IBK, BANBIF, ATLÁNTIDA Ó FICOHSA asumirá el costo
              de la comisión interbancaria según corresponda su banco.
            </Box>
          </Flex>
          <Button
            variant="text"
            size="medium"
            style={{ color: '#1A4BEA' }}
            onClick={() => history.push('/app/profile/configuration')}
          >
            + Añadir / Cambiar cuenta Bancaria
          </Button>

          {(selectedOption === 'USD' && bankAccountNumberUSD !== '---') ||
          (selectedOption === 'PEN' && bankAccountNumberPEN !== '---') ||
          (selectedOption === 'HNL' && bankAccountIdHNL !== '---') ? (
            <></>
          ) : (
            <>
              <Text
                backgroundColor="#486FEE12"
                fontSize={'14px'}
                mb={10}
                style={{ width: isSmallerThan640 ? '100%' : '100%' }}
                mt={15}
                borderRadius={8}
              >
                Necesitas crear una cuenta en {selectedOption} para continuar
              </Text>
            </>
          )}
        </Box>
      ),
    },
    /* Choosing an amount */
    {
      label: `¿Cuánto quieres retirar? (Mínimo: ${fiatMinimum} ${selectedOption})`,
      content: (
        <Box style={{ width: isSmallerThan640 ? '100%' : '40%' }}>
          <InputGroup size="md" w={'100%'}>
            <Input
              w={'100%'}
              style={{
                padding: 5,
                borderWidth: 1,
                borderColor: 'lightgrey',
                borderRadius: 5,
              }}
              value={valueToWithdraw}
              onChange={event => setValueToWithdraw(event.target.value)}
              placeholder="0.00"
            />
            <InputRightElement h={'100%'} pr={5}>
              <Button
                variant="text"
                size="small"
                onClick={() => setValueToWithdraw(bankBalance[selectedOption])}
              >
                Total
              </Button>
            </InputRightElement>
          </InputGroup>
          <Text align="right" color="gray" fontSize={'10px'}>
            Monto disponible:{' '}
            {selectedOption === 'PEN'
              ? `S/${Number.parseFloat(bankBalance[selectedOption]).toFixed(2)}`
              : selectedOption === 'USD'
              ? `$${Number.parseFloat(bankBalance[selectedOption]).toFixed(2)}`
              : selectedOption === 'HNL'
              ? `L/${Number.parseFloat(bankBalance[selectedOption]).toFixed(2)}`
              : ''}
          </Text>
        </Box>
      ),
    },
    /* Confirming */
    {
      label: 'Confirma y recibe tu dinero',
      content: (
        <Box style={{ width: isSmallerThan640 ? '100%' : '40%' }}>
          <Box
            style={{
              borderWidth: 1,
              borderColor: 'lightgrey',
              padding: 10,
              borderRadius: 10,
              color: 'lightgrey',
            }}
          >
            <Flex backgroundColor="#F6F6F6" mt="10">
              <Box p="4" bg="red.400" fontSize={'12px'} color="gray">
                Monto a retirar
              </Box>
              <Spacer />
              <Box p="4" bg="green.400" fontSize={'12px'} color="#1A4BEA">
                {valueToWithdraw}{' '}
                {selectedOption === 'PEN'
                  ? 'PEN'
                  : selectedOption === 'HNL'
                  ? 'HNL'
                  : 'USD'}
              </Box>
            </Flex>
            <Flex>
              <Box p="4" bg="red.400" fontSize={'12px'} color="gray">
                Costo de retiro
              </Box>
              <Spacer />
              <Box p="4" bg="green.400" fontSize={'12px'} color="#1A4BEA">
                {selectedOption === 'PEN'
                  ? '3 PEN'
                  : selectedOption === 'HNL'
                  ? '52 HNL'
                  : '1 USD'}
              </Box>
            </Flex>
            <Flex backgroundColor="#F6F6F6">
              <Box p="4" bg="red.400" fontSize={'12px'} color="gray">
                Dirección de Banco
              </Box>
              <Spacer />
              <Box p="4" bg="green.400" fontSize={'12px'} color="#1A4BEA">
                {selectedOption === 'PEN'
                  ? BancoPENOptionsObject[bankNamePEN]?.label
                  : selectedOption === 'HNL'
                  ? BancoHNLOptionsObject[bankNameHNL]?.label
                  : BancoUSDOptionsObject[bankNameUSD]?.label}
              </Box>
            </Flex>
            <Flex>
              <Box p="4" bg="red.400" fontSize={'12px'} color="gray">
                Número de cuenta
              </Box>
              <Spacer />
              <Box p="4" bg="green.400" fontSize={'12px'} color="#1A4BEA">
                {selectedOption === 'PEN'
                  ? bankAccountNumberPEN
                  : selectedOption === 'HNL'
                  ? bankAccountNumberHNL
                  : bankAccountNumberUSD}
              </Box>
            </Flex>
          </Box>
          <Checkbox
            label="Declaro que toda la información ingresada es correcta."
            value={checked}
            onChange={handleChange}
          />
        </Box>
      ),
    },
  ];

  return (
    <Stack align={'center'} mb={5} w={'100%'}>
      <ThemeProvider theme={theme}>
        <Stack align={'center'} mb={5}>
          <Heading fontSize={'24'} fontWeight={'bold'}>
            Retirar
          </Heading>
        </Stack>
        <Box
          bg={useColorModeValue('white', 'gray.800')}
          pb={5}
          mb={10}
          paddingInline={10}
          w="100%"
          align={'center'}
          alignItems="center"
          style={{ overflow: 'visible' }}
        >
          <Stepper activeStep={activeStep} orientation="vertical">
            {/* HERE YOU ADD THE NEW FIAT CURRENCY */}
            {/* ---------------------------------- */}
            {selectedOption === 'PEN' ||
            selectedOption === 'USD' ||
            selectedOption === 'HNL'
              ? stepsFiat.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      {step.content}
                      <Box sx={{ mb: 2 }}>
                        <div>
                          {index === stepsFiat.length - 1 ? (
                            <Button
                              disabled={!checked}
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {isClicked ? (
                                <>
                                  <Spinner
                                    size="xl"
                                    style={{ fontSize: '16px' }}
                                  />
                                </>
                              ) : (
                                'Confirmar retiro'
                              )}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Siguiente
                            </Button>
                          )}
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Regresar
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))
              : stepsCrypto.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      {step.content}
                      <Box sx={{ mb: 2 }}>
                        <div>
                          {/*
                          <Text
                            backgroundColor="#486FEE12"
                            fontSize={'14px'}
                            mb={10}
                            style={{
                              width: isSmallerThan640 ? '100%' : '100%',
                            }}
                            mt={15}
                            borderRadius={8}
                          >
                            En este momento el servicio no está disponible
                          </Text>
                        */}

                          <Button
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={
                              isClicked ||
                              loading ||
                              (feePerOperation === '-' &&
                                manualFeePerOperation === 0 &&
                                index === stepsCrypto.length - 1)
                            }
                            isLoading={isClicked || loading}
                          >
                            {index === stepsCrypto.length - 1 ? (
                              isClicked || loading ? (
                                <Spinner
                                  size="xl"
                                  style={{ fontSize: '16px' }}
                                />
                              ) : (
                                'Confirmar retiro'
                              )
                            ) : (
                              'Siguiente'
                            )}
                          </Button>

                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Regresar
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
            {activeStep ===
              // HERE YOU ADD THE NEW FIAT CURRENCY
              // ----------------------------------
              (selectedOption === 'PEN' ||
              selectedOption === 'USD' ||
              selectedOption === 'HNL'
                ? stepsFiat.length
                : stepsCrypto.length) && (
              <Box
                style={{
                  width: isSmallerThan640 ? '90%' : '40%',
                  alignSelf: 'center',
                }}
              >
                <Paper
                  square
                  elevation={0}
                  sx={{ p: 3 }}
                  style={{ width: '100%', alignSelf: 'center' }}
                >
                  <Image
                    src={
                      selectedOption === 'PEN' || selectedOption === 'USD'
                        ? ModalImage2
                        : ModalImage1
                    }
                    alt="Finish Image"
                    w={320}
                    mr={10}
                  />
                  <Typography
                    style={{
                      color: '#1A4BEA',
                      fontSize: 18,
                      fontWeight: 'bold',
                    }}
                  >
                    {selectedOption === 'PEN' ||
                    selectedOption === 'USD' ||
                    selectedOption === 'HNL'
                      ? 'Tu retiro fue exitoso'
                      : 'Tu retiro de criptomonedas fue exitoso'}
                  </Typography>
                  {selectedOption === 'PEN' ||
                  selectedOption === 'USD' ||
                  selectedOption === 'HNL' ? (
                    <Text color="gray">
                      Realizaste los pasos correctamente, ahora nos queda
                      verificar la operación (hasta 1 día hábil en ser procesado
                      y estar disponible en tu cuenta). Luego de ello, el dinero
                      será abonado a tu cuenta.
                    </Text>
                  ) : (
                    <Text color="gray">
                      Se esta realizando el retiro de tus criptomonedas esto
                      puede tomar diferente variación de tiempo dependiendo de
                      la carga que hay en la red de{' '}
                      {CryptoMapedArray[selectedOption].value}.
                    </Text>
                  )}
                  <Text color="gray">
                    * dia hábil: Lunes a viernes (no incluye feriados)
                  </Text>
                  <Button
                    variant="contained"
                    onClick={handleReset}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Está bien
                  </Button>
                </Paper>
              </Box>
            )}
          </Stepper>
        </Box>
      </ThemeProvider>
    </Stack>
  );
};

export default Deposit;
